<template>
  <v-container>
    <v-col cols="12">
      <h2 style="float: left; padding-top: 10px">
        {{ subcategory[lang] ? subcategory[lang].name : "" }}
      </h2>
      <div style="float: right">
        <v-btn
          light
          icon
          @click="open_modal_category(subcategory, subcategory.parentId)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn light icon @click="show_delete_dialog(subcategory.id, null)">
          <v-icon class="mx-1">mdi-close</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <hr />
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">{{ $t("name") }}</th>
            <th class="primary--text">{{ $t("description") }}</th>
            <th class="text-right primary--text">{{ $t("price") }}</th>
            <th class="primary--text"></th>
          </tr>
        </thead>

        <draggable
          v-model="subcategoryentries"
          group="subcategoryentries"
          @start="drag = true"
          @end="drag = false"
          tag="tbody"
          @add="addedEntry"
        >
          <tr v-for="(item, index) in subcategoryentries" v-bind:key="index">
            <td>{{ item[lang] ? item[lang].name : "" }}</td>
            <td>{{ item[lang] ? item[lang].description : "" }}</td>
            <td class="text-right">
              <div v-if="item.price">
                {{
                  parseFloat(item.price)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}€
              </div>
            </td>
            <td
              class="text-right"
              style="display: flex; gap: 1rem; flex-direction: row-reverse"
            >
              <v-btn
                light
                icon
                @click="open_modal_edit_entry(subcategory.id, item, item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                light
                icon
                @click="show_delete_dialog(subcategory.id, item.id)"
              >
                <v-icon class="mx-1">mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </draggable>
      </v-simple-table>
    </v-col>
    <v-row justify="center">
      <delete-dialog
        @close-dialog="close_delete_dialog"
        @confirm-dialog="confirm_delete_dialog"
        :dialog="dialog"
        :categoryId="this.categoryId"
        :entryId="this.entryId"
      ></delete-dialog>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["subcategory", "lang"],
  components: {
    "delete-dialog": () => import("../dialog/DeleteDialog"),
    draggable,
  },
  data() {
    return {
      dialog: false,
      categoryId: null,
      entryId: null,
    };
  },
  computed: {
    subcategoryentries: {
      get() {
        let entries = [];
        let entryDir = this.subcategory.entries;
        if (entryDir) {
          Object.keys(entryDir).forEach(function (key) {
            entryDir[key]["id"] = key;
            entries.push(entryDir[key]);
          });
        }
        return entries.sort((a, b) => {
          return a.index - b.index;
        });
      },
      set(value) {
        value.forEach((el, index) => {
          for (let i in this.subcategory.entries) {
            if (i == el.id) {
              this.subcategory.entries[i].index = index;
              break;
            }
          }
        });
        this.$store.dispatch("UPDATE_CATEGORY", { category: this.subcategory });
      },
    },
  },
  methods: {
    show_delete_dialog(categoryId, entryId) {
      this.categoryId = categoryId;
      this.entryId = entryId;
      this.dialog = true;
    },
    confirm_delete_dialog: function ({ categoryId, entryId }) {
      this.dialog = false;
      if (!entryId) {
        console.log("delete category " + categoryId);
        this.delete_category(categoryId);
      } else {
        console.log("delete entry " + categoryId + " " + entryId);
        this.delete_entry(categoryId, entryId);
      }
    },
    close_delete_dialog: function (event) {
      this.dialog = false;
    },
    delete_category: function (categoryId) {
      this.$store
        .dispatch("DELETE_CATEGORY", { categoryId: categoryId })
        .then(() => {
          this.$notify({
            group: "foo",
            title: this.$t("deleted"),
            text: this.$t("category_deleted"),
            type: "error",
          });
        });
    },
    delete_entry: function (categoryId, entryId) {
      this.$store
        .dispatch("DELETE_ENTRY", {
          categoryId: categoryId,
          entryId: entryId,
        })
        .then(() => {
          this.$notify({
            group: "foo",
            title: this.$t("deleted"),
            text: this.$t("entry_deleted"),
            type: "error",
          });
        });
    },
    open_modal_new_entry: function (categoryId) {
      this.$emit("open_modal_new_entry", { categoryId: categoryId });
    },
    open_modal_edit_entry: function (categoryId, item, itemId) {
      this.$emit("open_modal_edit_entry", {
        categoryId: categoryId,
        item: item,
        itemId: itemId,
      });
    },
    open_modal_category: function (category, parentId) {
      this.$emit("open_modal_category", {
        category: category,
        parentId: parentId,
      });
    },
    addedEntry(evt) {
      console.log(evt.oldIndex);
    },
  },
};
</script>
